<template>
    <div data-test="headerTooltipWrapper" class="header-tooltip">
        <span data-test="headerTooltipToggle" @mouseover="toggleInfoBox(true)" @mouseleave="toggleInfoBox(false)" class="tooltip-icon"></span>
        <div data-test="headerTooltipContent" v-if="isShowingInfoBox" class="header-tooltip__box">
            <b>Names in blue are automatically generated.</b>
            <br>
            When unchanged, they will automatically get updated when settings are changed inn the previous step.
            <br> <br>
            <b> Once a name has been changed (showing in black), the automatic naming feature will not be available! </b>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderTooltip',
    data () {
        return {
            isShowingInfoBox: false,
        }
    },
    methods: {
        toggleInfoBox (value) {
            this.isShowingInfoBox = value
        },
    },
}
</script>

<style lang="scss" scoped>
.header-tooltip {
    display: inline-block;
    vertical-align: middle;
    position: relative;

    &__box {
        position: absolute;
        font-weight: 400;
        top: 7px;
        left: 15px;
        font-size: 12px;
        width: 250px;
        height: auto;
        padding: 10px;
        white-space: normal;
        background-color: #E0E9FD;
        border: 2px solid #5867D4;
        border-radius: 5px;
        font-family: inherit;
        letter-spacing: normal;
        line-height: 1.3;
        color: black;
        z-index: 10;
    }
}
.tooltip-icon {
    &::after {
        font-family: 'Material Icons';
        content: '\e88e';
        margin-left: 10px;
        font-size: 18px;
        color: #5867D4;
        border: none;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        display: flex;
        background-color: white;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 100;
    }
}
</style>
