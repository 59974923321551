<template>
    <div class="flex align-items-center w-percentage-100">
        <div class="flex-1">
            <mercur-input
                v-if="isEditing"
                data-test="InternalArtworkNameEditNameInput"
                class="mb-0"
                ref="input"
                v-model="localValue">
            </mercur-input>
            <template v-else>{{ value }}</template>
        </div>
        <mercur-tooltip v-if="!isEditing">
            <template slot="label">Edit value</template>
            <mercur-button data-test="InternalArtworkNameEditButton" class="btn btn-icon" @click="isEditing = true">
                <i class="fas fa-pen"></i>
            </mercur-button>
        </mercur-tooltip>
        <template v-else>
            <mercur-tooltip>
                <template slot="label">Cancel editing</template>
                <mercur-button class="btn btn-icon" :disabled="isSaving" @click="isEditing = false">
                    <i class="fas fa-times"></i>
                </mercur-button>
            </mercur-tooltip>
            <mercur-tooltip>
                <template slot="label">Save changes</template>
                <mercur-button data-test="InternalArtworkNameEditSaveButton" class="btn btn-icon" :disabled="isSaving" @click="saveArtworkConfigurationSetting">
                    <i class="fas fa-save"></i>
                </mercur-button>
            </mercur-tooltip>
        </template>
    </div>
</template>

<script>
import CONFIG from '@root/config'
export default {
    name: 'InternalArtworkNameEdit',
    props: {
        value: {
            readonly: true,
        },
        artworkSetting: {},
    },
    data () {
        return {
            isEditing: false,
            localValue: null,
            isSaving: false,
        }
    },
    methods: {
        async saveArtworkConfigurationSetting () {
            const payload = {
                ...this.artworkSetting,
                internalArtworkName: this.localValue,
            }
            if (this.localValue !== this.value) {
                payload.isCustom = true
            }
            this.isSaving = true
            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.ARTWORK_CONFIGURATION_SETTINGS.SAVE
            await this.$api.post(url, payload).then(({ data }) => {
                this.$emit('updated', payload)
                this.isEditing = false
                this.$root.$emit('notification:global', {
                    message: 'Artwork configuration setting was updated.',
                    type: 'success',
                })
            }).finally(() => {
                this.isSaving = false
            })
        },
    },
    created () {
        this.localValue = this.value
    },
}
</script>
