<template>
    <div class="full-height-layout fhf pb-10">
        <mercur-card class="mx-0 p-4 fhf u-no-horizontal-overflow">
            <h2 class="font-weight-normal">Internal Artwork name: {{ configuration.uniqueCombinationsAmount }} Unique combinations of {{ configuration.productConfigurationName }}</h2>
            <div>
                <div class="row">
                    <div class="col-6">
                        <div class="flex align-items-center">
                            <mercur-input class="w-100 mb-0" v-model="attributeSearch" placeholder="Search"></mercur-input>
                            <i class="fas fa-search artwork-management-search-icon ml-2"></i>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="flex align-items-center">
                            <mercur-input class="w-100 mb-0" v-model="artworkNameSearch" placeholder="Search"></mercur-input>
                            <i class="fas fa-search artwork-management-search-icon ml-2"></i>
                        </div>
                    </div>
                </div>

                <table class="mt-20 artwork-management-table">
                    <tr class="artwork-management-table__header">
                        <th
                            class="artwork-management-table__header-cell"
                            v-for="attribute in configuration.internalArtworkNaming"
                            :key="attribute.value">
                            {{ attribute.value }}
                        </th>
                        <th class="artwork-management-table__header-cell">
                            INTERNAL ARTWORK NAME <header-tooltip></header-tooltip>
                        </th>
                    </tr>
                    <tr
                        class="artwork-management-table__row"
                        v-for="artworkSetting in filteredItems.length ? filteredItems : items"
                        :key="artworkSetting.internalArtworkName">

                        <td
                            class="artwork-management-table__cell"
                            v-for="(attribute, index) in configuration.internalArtworkNaming"
                            :key="attribute.value + index">
                            {{ getMatchingOption(attribute, artworkSetting) }}
                        </td>

                        <td class="artwork-management-table__cell text-artwork-blue">
                            <internal-artwork-name-edit :artwork-setting="artworkSetting" :value="artworkSetting.internalArtworkName"></internal-artwork-name-edit>
                        </td>
                    </tr>
                </table>

            </div>
        </mercur-card>
        <mercur-card class="mt-15 ml-0 mr-0 text-right">
            <mercur-button
                :disabled="isSavingConfiguration"
                @click.prevent="goToNextStep"
                class="btn btn-raised btn-yellow text-uppercase">
                <span>Save + Continue</span>
                <i class="fas fa-forward artwork-management-save-icon ml-1"></i>
            </mercur-button>
        </mercur-card>
    </div>
</template>

<script>
import ArtworkManagementConfigurationsMixin from '@/views/artwork_management/configurations/ArtworkManagementConfigurationsMixin'
import HeaderTooltip from '@/components/table/HeaderTooltip'
import InternalArtworkNameEdit from '@/components/artwork_management/InternalArtworkNameEdit'
export default {
    name: 'ArtworkManagementUniqueCombinations',
    mixins: [ ArtworkManagementConfigurationsMixin ],
    components: { HeaderTooltip, InternalArtworkNameEdit },
    data () {
        return {
            items: null,
            filteredItems: [],
            attributeSearch: '',
            artworkNameSearch: '',
            isShowingInfoBox: false,
        }
    },
    watch: {
        attributeSearch (searchString) {
            this.filteredItems = this.items.filter(item => {
                const parsedItem = JSON.parse(JSON.stringify(item))
                return parsedItem.attributeConfiguration.map(e => e.attributeOption).join(' ').includes(searchString)
            })
        },
        artworkNameSearch (searchString) {
            this.filteredItems = this.items.filter(item => item.internalArtworkName.includes(searchString))
        },
    },
    methods: {
        goToNextStep () {
            this.saveConfiguration().then(() => {
                this.$router.push({
                    name: 'BasicArtworkConfig',
                    params: {
                        ...this.params,
                    },
                })
            })
        },
        getMatchingOption (attribute, artworkSetting) {
            const matchedAttribute = artworkSetting.attributeConfiguration.find(e => e.attributeName === attribute.value)
            if (!matchedAttribute) {
                return '-'
            }

            return matchedAttribute.attributeOption
        },
    },
    created () {
        this.items = this.configurationSettings
    },
}
</script>
